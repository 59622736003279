import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

type CampaignType = 'sms' | 'drip'

type CampaignExportStatus = {
    status: string
    message: string
}

interface ICampaignExportService {
    $axios: NuxtAxiosInstance

    requestCampaignExport: (campaignId: string, campaignType: CampaignType) => Promise<any>
    getCampaignExportStatus: (campaignId: string, campaignType: CampaignType) => Promise<CampaignExportStatus>
    downloadCampaignExport: (campaignId: string, campaignType: CampaignType) => Promise<any>
}

export default class CampaignExportService implements ICampaignExportService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  public requestCampaignExport (campaignId: string, campaignType: string) {
    return this.$axios.$post(`/campaigns/${campaignId}/export/${campaignType}/`)
  }

  public getCampaignExportStatus (campaignId: string, campaignType: string) {
    return this.$axios.$get(`/campaigns/${campaignId}/export/${campaignType}/status/`)
  }

  public downloadCampaignExport (campaignId: string, campaignType: string) {
    return this.$axios.$get(`/campaigns/${campaignId}/export/${campaignType}/download/`)
  }
}
